<template>
  <div :class="classContainer" class="s-select__container">
    <div class="s-select__inner">
      <div class="s-select__label">
        <slot name="label">
          {{ labelName }}
        </slot>
      </div>

      <div class="s-select__wrapper">
        <div v-if="$scopedSlots.iconLeft" class="s-select__icon-left">
          <slot name="iconLeft" />
        </div>

        <vue-multiselect
          ref="select"
          v-model="innerValue"
          v-bind="bindingsSelect"
          :options="sortedOptions"
          :option-height="optionHeight"
          :multiple="multiple"
          :group-values="groupValues"
          :selected-label="''"
          :select-label="''"
          :deselect-label="''"
          :select-group-label="''"
          :deselect-group-label="''"
          :block-keys="blockKeys"
          :close-on-select="!multiple"
          :limit-text="limitText"
          :limit="limit"
          class="s-select__select"
          :class="{ multiple: multiple, wider: wider, 'wider-right': widerRight, 'hide-options-menu': hideOptionsMenu}"
          @input="onInput"
          @select="onSelect"
          @remove="onRemove"
          @close="onClose"
          @open="onFocus"
          @tag="onTag"
          @search-change="onSearchChange"
        >
          <template #caret>
            <slot v-if="$scopedSlots.caret" name="caret" />

            <v-icon v-if="!hideOptionsMenu" color="info" class="cursor-pointer s-select__caret"> mdi-chevron-down </v-icon>
          </template>
          <template #selection v-if="multiple && Array.isArray(innerValue) && innerValue.length > 1">
            <span
              class="multiselect__single"
              style="color: #3F83F8"
            >{{$t('base.multiselectSelected', {count: innerValue.length})}}</span>
          </template>
          <template #beforeList>
            <slot name="beforeList" />
          </template>

          <template #option="optionProps">
            <div class="s-select__option">
              <span v-if="$scopedSlots.option" class="s-select__option__content">
                <slot name="option" v-bind="optionProps" />
              </span>

              <span v-else class="s-select__option__content">
                {{ getOptionLabel(optionProps.option) }}
              </span>

              <ui-checkbox
                v-if="multiple"
                :value="isSelected(optionProps.option)"
                hide-details
                class="s-select__option__checkbox"
                color="black lighten-6"
              />
            </div>
          </template>

          <template #singleLabel="optionProps">
            <slot v-if="!hideValuesFromInputArea" name="singleLabel" v-bind="optionProps" />

            <span v-else />
          </template>

          <template></template>

          <template #noOptions="optionProps">
            <slot name="noOptions" v-bind="optionProps" />
          </template>

          <template #noResult>
            <div class="s-select__text">
              {{ $t('base.noElementsFound') }}
            </div>
          </template>

          <template #tag="optionProps">
            <slot v-if="!hideValuesFromInputArea" name="tag" v-bind="optionProps" />

            <span v-else />
          </template>

          <template #afterList>
            <slot v-if="$scopedSlots.afterList" />

            <template v-else-if="lazyLoading">
              <div v-if="isLoadMoreEmpty" class="s-select__no-more">
                {{ $t('base.noMoreItems') }}
              </div>

              <div v-show="showSpinner" ref="lazySpinner" class="s-select__spinner">
                <v-progress-circular indeterminate color="blue500" />
              </div>

              <div class="s-select__actions lazy__actions" :class="{'d-none': !multiple || !query, 'd-flex': multiple && query || showSelectAllBtn}">
                <div class="s-select__actions__base">
                  <ui-button theme="outline" size="small" class="lazy-select-all-btn" @click="onSelectAll">
                    {{ $t('base.selectAll') }}
                  </ui-button>
                  <ui-button theme="outline" size="small" class="lazy-clear-all-btn" @click="onRemoveAll">
                    <span class="error--text text--base">
                    {{ $t('base.clearAll') }}
                  </span>
                  </ui-button>
                </div>
              </div>

              <div class="s-select__actions lazy__actions" :class="{'d-none': !showSubmitBtn}">
                <div class="s-select__actions__base">
                  <ui-button theme="outline" size="small" class="lazy-select-all-btn" @click="onSubmit">
                    {{ $t('base.submit') }}
                  </ui-button>
                  <ui-button theme="outline" size="small" class="lazy-clear-all-btn" @click="close">
                    <span class="error--text text--base">
                    {{ $t('base.cancel') }}
                  </span>
                  </ui-button>
                </div>
              </div>
            </template>
            <div v-else-if="multiple" class="s-select__actions">
              <slot name="actions" />

              <div class="s-select__actions__base">
                <ui-button theme="outline" size="small" @click="onSelectAll">
                  <template #iconLeft>
                    <v-icon color="black darken-3" size="18">mdi-check-all</v-icon>
                  </template>
                  {{ $t('base.selectAll') }}
                </ui-button>
                <ui-button theme="outline" size="small" @click="onRemoveAll">
                  <template #iconLeft>
                    <v-icon color="error base" size="18">mdi-delete-sweep</v-icon>
                  </template>
                  <span class="error--text text--base">
                    {{ $t('base.clearAll') }}
                  </span>
                </ui-button>
              </div>
            </div>

            <div v-else-if="$scopedSlots.actions" class="s-select__actions">
              <slot name="actions" />
            </div>
          </template>
        </vue-multiselect>

        <div v-if="$scopedSlots.iconRight" class="s-select__icon-right">
          <slot name="iconRight" />
        </div>
      </div>

      <div v-if="showHint" class="s-select__hint">
        {{ hint || getErrorMsg }}
      </div>
    </div>
  </div>
</template>

<script>
import { debounce, isArray, omit } from 'lodash'
import { getErrorMsg } from '@/helpers/validation'

export default {
  name: 'ui-select',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [String, Array, Object],
    },
    lazyLoading: {
      type: Boolean,
      default: false,
    },
    noMoreOptions: {
      type: Boolean,
      default: false,
    },
    doNotClearAllArray: {
      type: Boolean,
      default: false,
    },
    lastLoadPage: {
      type: Number,
      default: 1,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    wider: {
      type: Boolean,
      default: false,
    },
    widerRight: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    showSubmitBtn: {
      type: Boolean,
      default: false,
    },
    showSelectAllBtn: {
      type: Boolean,
      default: false,
    },
    hideValuesFromInputArea: {
      type: Boolean,
      default: false,
    },
    hideOptionsMenu: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    returnProp: {
      type: String,
      default: '',
    },
    groupValues: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    optionHeight: {
      type: Number,
      default: 40,
    },
    value: {
      type: [Object, String, Array, Number, Boolean],
      default: null,
    },
    label: {
      type: String,
      default: 'name',
    },
    trackBy: {
      type: String,
      default: 'id',
    },
    defaultValue: {
      type: [Number, String],
      default: undefined,
    },
    labelName: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'default',
      validator(type) {
        return ['small', 'default', 'large'].includes(type)
      },
    },
    oneLineMode: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 1,
    },
    inverseOptionColor: {
      type: Boolean,
      default: false,
    },
    borderColor: {
      type: String,
      default: 'gray',
      validator(type) {
        return ['gray', 'success', 'error', 'warning'].includes(type)
      },
    },
    query: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      innerValue: null,
      isFocused: false,
      blockKeys: ['Delete'],
    }
  },

  computed: {
    classContainer() {
      return [
        `s-select--size-${this.size}`,
        `s-select--border-${this.borderColor}`,
        { 's-select--is-focused': this.isFocused },
        { 's-select--label-fixed': this.isLabelFixed },
        { 's-select--error': this.hasError },
        { 's-select--hide-details': this.hideDetails },
        { 's-select--disabled': this.disabled },
        { 's-select--hide-values': this.hideValuesFromInputArea },
        { 's-select--icon-left': this.$scopedSlots.iconLeft },
        { 's-select--icon-right': this.$scopedSlots.iconRight },
        { 's-select--custom-value': !this.isDefaultValueSelected },
        { 's-select--one-line': this.oneLineMode },
        { 's-select--has-hidden-values': this.hasHiddenValues },
        { 's-select--inverted-color': this.inverseOptionColor },
      ]
    },
    isDefaultValueSelected(){
      if(this.defaultValue === undefined) return true
      return this.innerValue?.[this.trackBy] === this.defaultValue
    },
    isLabelFixed() {
      return (!this.isEmptyValue && !this.hideValuesFromInputArea) || this.isFocused
    },

    isEmptyValue() {
      return !this.innerValue || (Array.isArray(this.innerValue) && this.innerValue?.length === 0)
    },

    hasHiddenValues() {
      return isArray(this.innerValue) && this.innerValue.length > this.limit
    },

    bindingsSelect() {
      return {
        ...omit(this.$attrs, ['value', 'label', 'trackBy', 'limit']),
        placeholder: this.placeholder || '',
        label: this.label,
        trackBy: this.trackBy,
      }
    },

    showHint() {
      return (this.hint || this.getErrorMsg) && !this.hideDetails
    },

    isLoadMoreEmpty() {
      return this.noMoreOptions && this.hasOptions
    },

    showSpinner() {
      return !this.isLoadMoreEmpty && this.hasOptions
    },

    hasOptions() {
      return this.options.length > 0
    },

    getErrorMsg() {
      if (Array.isArray(this.errorMessages) || typeof this.errorMessages === 'string') {
        return this.errorMessages
      }

      return getErrorMsg(this.errorMessages).join('. ')
    },
    hasError() {
      return !!this.getErrorMsg
    },
    sortedOptions() {
      const selected = this.innerValue?.length ? this.innerValue : []
      const notSelected = this.options?.length ? this.options.filter(
        option => !selected.some(s => s[this.trackBy] === option[this.trackBy])
      ) : [];
      return [...selected, ...notSelected];
    },
  },

  watch: {
    value: {
      handler(nextValue) {
        if (this.returnProp) {
          if (this.multiple && isArray(nextValue)) {
            this.initMultipleData(nextValue)
          } else {
            this.initSingleData(nextValue)
          }
        } else {
          this.innerValue = nextValue
        }
      },
      immediate: true,
    },

    options: {
      handler() {
        if (this.returnProp) {
          if (this.multiple && isArray(this.innerValue)) {
            this.initMultipleData(this.innerValue)
          } else {
            this.initSingleData(this.innerValue)
          }
        }
      },
    },
  },

  mounted() {
    if (this.lazyLoading) {
      this.initLazyLoading()
    }
  },

  methods: {
    limitText(count) {
      return this.$t('base.limitText', { count })
    },
    initSingleData(nextValue) {
      let selectedOption = null
      // find value in groups if select has groupValues
      if (this.groupValues) {
        const groupValuesArray = this.options.reduce((values, current) => {
          return [...values, ...current[this.groupValues]]
        }, [])

        selectedOption = groupValuesArray.find(
          groupValue => groupValue[this.returnProp] === nextValue,
        )
      } else {
        selectedOption = this.options.find(
          option =>
            typeof option[this.returnProp] !== 'undefined' && option[this.returnProp] === nextValue,
        )
      }
      this.innerValue = selectedOption || nextValue
    },

    initMultipleData(nextValue) {
      let selectedOptions = []
      // find value in groups if select has groupValues
      if (this.groupValues) {
        const groupValuesArray = this.options.reduce((values, current) => {
          return [...values, ...current[this.groupValues]]
        }, [])

        selectedOptions = nextValue
          .map(value =>
            groupValuesArray.find(
              option => option[this.returnProp] && option[this.returnProp] === value,
            ),
          )
          .filter(value => !!value)
      } else {
        selectedOptions = nextValue.map(
          value =>
            this.options.find(
              option => option[this.returnProp] && option[this.returnProp] === value,
            ) || value,
        )
      }

      this.innerValue = selectedOptions.every(option => typeof option !== 'undefined')
        ? selectedOptions
        : nextValue
    },

    initLazyLoading() {
      const rootEl = this.$refs.lazySpinner?.parentNode?.parentNode
      const options = {
        root: rootEl || null,
        rootMargin: '0px',
        threshold: 0.1,
      }

      const observer = new IntersectionObserver(entries => {
        if (entries.length && entries[0].isIntersecting && !this.noMoreOptions) {
          this.$emit('load-more')
        }
      }, options)

      if (this.$refs.lazySpinner) {
        observer.observe(this.$refs.lazySpinner)
      }
    },

    getOptionLabel(option) {
      if (!this.$refs.select) {
        return ''
      }

      return this.$refs.select.getOptionLabel(option)
    },

    isSelected(option) {
      if (!this.$refs.select) {
        return false
      }

      return this.$refs.select.isSelected(option)
    },

    onFocus() {
      this.isFocused = true
      this.$emit('focused')
    },

    onBlur() {
      this.isFocused = false
    },

    onClose() {
      this.onBlur()
      this.$emit('close')
    },

    onInput(value) {
      if (this.returnProp) {
        if (this.multiple) {
          if (isArray(value) && value.every(v => Object.hasOwnProperty.call(v, this.returnProp))) {
            this.$emit(
              'input',
              value.map(v => v[this.returnProp]),
            )
          } else {
            console.warn("Value isn't Array or not all elements has selected field")
            this.$emit('input', value)
          }
        } else if (value && Object.hasOwnProperty.call(value, this.returnProp)) {
          this.$emit('input', value[this.returnProp])
        } else {
          console.warn("Option doesn't have selected returnProp field")
          this.$emit('input', value)
        }
      } else {
        this.$emit('input', value)
      }
    },

    onSelect(option) {
      if (this.returnProp) {
        if (Object.hasOwnProperty.call(option, this.returnProp)) {
          this.$emit('select', option[this.returnProp])
        } else {
          console.warn("Option doesn't have selected returnProp field")
          this.$emit('select', option)
        }
      } else {
        this.$emit('select', option)
      }
    },

    async onSelectAll() {
      if(this.lazyLoading){
        const firstListEl = this.$refs.select.$el.querySelectorAll('.multiselect__content li.multiselect__element')
        let indexEl = -1
        for(let i = 0; i < firstListEl.length; i++){
          const checkbox = firstListEl[i].querySelector('span input[type=checkbox]').getAttribute('aria-checked')
          if (checkbox === 'false') {
            indexEl = i
            break
          }
        }
        if (indexEl >= 0) firstListEl[indexEl].querySelector('span').click()
        this.$emit('get-all-options-selected')
        this.onSearchChange('')
      } else {
        this.onInput(this.options)
      }
    },

    onRemoveAll() {
      this.$emit('removeAll')
      if(!this.doNotClearAllArray)this.onInput([])
    },
    onSubmit() {
      this.$emit('onSubmit')
    },
    onCancel() {
      this.$emit('onCancel')
    },

    onRemove(option) {
      this.$emit('remove', option)
    },

    onTag(value) {
      this.$emit('tag', value)
    },

    onSearchChange: debounce(function (value) {
      this.$emit('search-change', value)
    }, 300),

    close() {
      this.$refs.select.deactivate()
    },
  },
}
</script>
<style lang="scss">
.hide-options-menu .multiselect__content-wrapper {
  display: none!important;
}
.v-list .v-list-item--active {
  background-color: var(--v-primary100-base)!important;
}
.s-select {
  &__container {
    position: relative;
  }

  &__label {
    font-size: 14px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 50;
    padding: 0 2px;
    color: var(--v-black-darken1);
    background-color: #fff;
    transition: font-size 0.25s ease-in-out, left 0.15s ease-in-out, top 0.15s ease-in-out;
    pointer-events: none;
    will-change: left, top, font-size;
  }

  &__inner {
    z-index: 1;
  }

  &__wrapper {
    position: relative;
  }

  &__icon-left,
  &__icon-right {
    width: 24px;
    height: 24px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 8px;
    z-index: 1;
  }

  &__icon-left {
    left: 12px;
  }

  &__icon-right {
    right: 38px;
  }

  &__caret {
    position: absolute !important;
    top: 21px;
    right: 15px;
    transform: translateY(-50%);
    font-size: 20px !important;
    z-index: 1;
    padding-left: 20px;
    background: linear-gradient(270deg, #ffffff 50%, rgba(255, 255, 255, 0) 100%);
  }

  &__hint {
    font-size: 12px;
    line-height: 14px;
    color: #5f6163;
    padding-left: 12px;
    padding-top: 4px;
  }

  &__spinner {
    text-align: center;
    padding-top: 4px;
  }

  &__no-more {
    padding-top: 8px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: var(--v-blue500-base);
  }

  &__text {
    font-size: 12px;
    text-align: center;
    width: 100%;
    color: var(--v-blue500-base);
  }

  &__option {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__option__checkbox {
    flex-shrink: 0;
    margin: 0 !important;
    padding: 0 !important;

    .v-input--selection-controls__input {
      margin: 0;
    }
  }

  &__option__content {
    flex-grow: 1;
    width: 100%;
    display: block;
  }

  &__actions {
    position: sticky;
    bottom: -8px;
    width: 100%;
    background: #fff;
    z-index: 1;
    display: flex;
    align-items: center;
    padding: 8px;
    gap: 16px;
    &.lazy__actions {
      border-top: 1px solid #E4E4E4;
      padding: 12px;
    }
  }

  &__actions__base {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__select.multiple {
    .s-select__caret {
      padding-left: 0;
      background: none;
    }
  }
  &__select {
    &.wider .multiselect__content-wrapper {
      width: 200%!important;
    }
    &.wider-right .multiselect__content-wrapper {
      width: 200%!important;
      right: 0;
    }
    &.multiselect {
    }

    &.multiselect--active {
      z-index: unset;
    }

    .multiselect__tags {
      padding: 4px 40px 4px 15px;
      display: flex;
      flex-flow: row wrap;
      border: 1px solid var(--v-black-lighten6);
      border-radius: 6px;
      transition: border-color 0.25s ease-in-out;
    }

    .multiselect__strong {
      font-size: 0.75rem !important;
      font-weight: 400;
      color: var(--v-black-lighten2);
      margin-bottom: 0;
    }

    .multiselect__placeholder {
      font-size: 12px;
      margin-bottom: 0;
      padding: 0;
      display: flex;
      align-items: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: var(--v-black-lighten6);
    }

    .multiselect__tags-wrap {
      width: 100%;
      padding: 4px 0;
      display: flex;
      align-items: center;
      gap: 4px;
      flex-grow: 1;
      flex-wrap: wrap;
    }

    .multiselect__tag {
      background: var(--v-blue500-base);
      border-radius: 12px;
      font-size: 10px;
      padding: 0 22px 0 8px;
      height: 20px;
      margin: 0;
      line-height: 12px;
      align-content: center;
    }

    .multiselect__tag-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;

      &:after {
        display: block;
        width: 12px;
        border-radius: 50%;
        background: #fff;
        line-height: 12px;
        color: var(--v-blue500-base);
        will-change: transform;
      }

      &:hover {
        background: transparent;

        &:after {
          color: var(--v-blue500-base);
        }
      }
    }

    .multiselect__input,
    .multiselect__single {
      font-size: 12px;
      margin-bottom: 0;
      padding-left: 0;
      display: flex;
      align-items: center;
      color: var(--v-black-darken3);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .multiselect__content {
      padding-left: 0 !important;
    }

    .multiselect__content-wrapper {
      display: block;
      z-index: 90;
      width: 100%;
      min-width: 100%;
      padding-top: 8px;
      box-shadow: 1px 1px 7px rgba(62, 65, 73, 0.2);

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: #525252;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-track {
        background: #f3f3f3;
      }

      scrollbar-color: #525252 #f3f3f3;
      scrollbar-width: thin;
    }

    .multiselect__option--highlight,
    .multiselect__option--highlight::after {
      background: white !important;
      color: #5f6163;
    }

    .multiselect__option--highlight:hover {
      background: var(--v-black-lighten7) !important;
      color: #5f6163;
    }

    .multiselect__option--selected,
    .multiselect__option.multiselect__option--highlight.multiselect__option--selected {
      background-color: var(--v-blue500-base) !important;
      color: white !important;
      font-weight: 400;
    }

    .multiselect__option--selected.multiselect__option--highlight {
      background-color: var(--v-primary-lighten6) !important;
      color: #5f6163;
      font-weight: 400;
    }

    .multiselect__option--group.multiselect__option--highlight {
      color: #5f6163;
      background-color: #d9d9d9 !important;
    }

    .multiselect__option {
      font-size: 12px;
      padding: 0 12px;
      display: flex;
      align-items: center;
      white-space: normal;
    }

    &:hover {
      .multiselect__tags {
        //border-color: var(--v-primary-base);
      }
    }

    .multiselect__input {
      opacity: 0 !important;

      &::placeholder {
        color: var(--v-black-lighten6);
      }
    }

    .multiselect__placeholder {
      display: none !important;
    }
  }

  &--is-focused,
  &--is-focused:hover {
    .multiselect__tags {
      border-color: var(--v-black-lighten6);
    }
  }

  &--label-fixed {
    &.multiselect {
      min-height: 32px;
    }

    .s-select__label {
      top: -7px !important;
      left: 10px;
      z-index: 6;
      font-size: 10px;
      line-height: 14px;
      color: var(--v-black-darken3);
    }
  }
  &--custom-value {
    .multiselect .multiselect__single {
      color: var(--v-blue500-base);
    }
  }
  &--one-line {
    .multiselect__strong {
      position: absolute;
      top: 10px;
      right: 44px;
    }

    .multiselect__tags-wrap {
      flex-wrap: nowrap;
      overflow-x: hidden;
    }

    &.s-select--has-hidden-values {
      .multiselect__tags {
        padding-right: 88px;
      }
    }
  }

  &--inverted-color {
    .multiselect__tag {
      background: var(--v-error-base) !important;
    }

    .multiselect__tag-icon {
      &:after {
        color: var(--v-error-base);
      }
    }

    .multiselect__option--highlight:hover {
      background: var(--v-error-lighten6) !important;
    }

    .multiselect__option--selected,
    .multiselect__option.multiselect__option--highlight.multiselect__option--selected {
      background-color: var(--v-error-base) !important;
    }
  }

  &--error {
    .multiselect__tags,
    &:hover .multiselect__tags {
      border: 2px solid var(--v-error-base);
    }

    .s-select__hint,
    .s-select__label {
      color: var(--v-error-base);
    }
  }

  &--hide-details {
    margin: 0;
  }

  &--border {
    &-error {
      .multiselect__tags {
        border-color: var(--v-error-base);
      }
    }

    &-success {
      .multiselect__tags {
        border-color: var(--v-success-base);
      }
    }

    &-warning {
      .multiselect__tags {
        border-color: var(--v-warning-base);
      }
    }
  }

  &--disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &--hide-values {
    .s-select__select {
      min-height: initial;
      .multiselect__tags-wrap {
        display: none;
      }

      .multiselect__input {
        width: 100% !important;
        position: relative !important;
      }

      .multiselect__placeholder,
      .multiselect__single,
      .multiselect__strong {
        display: none;
      }
    }
  }

  &--label-fixed {
    .s-select__select {
      .multiselect__input {
        opacity: 1 !important;
      }

      .multiselect__placeholder {
        display: flex !important;
      }
    }
  }

  &--icon-left {
    .multiselect__tags {
      padding-left: 44px !important;
    }
  }

  &--icon-right {
    .multiselect__tags {
      padding-right: 68px !important;
    }
  }

  &--size-small {
    .s-select__label {
      top: 4px;
    }

    .s-select__icon-left,
    .s-select__icon-right {
      top: 2px;
    }

    .multiselect__single {
      font-size: 10px;
      line-height: 12px;
    }
    .multiselect,
    .multiselect__tags {
      min-height: 28px;
    }

    .multiselect__tags {
      padding: 0 10px;
    }

    .s-select__caret {
      top: 15px;
    }
  }

  &--size-large {
    .multiselect,
    .multiselect__tags {
      min-height: 56px;
    }

    .s-select__label {
      top: 17px;
    }

    .s-select__icon-left,
    .s-select__icon-right {
      top: 15px;
    }

    .s-select__caret {
      top: 27px;
    }
  }
}
.ui-btn-width.lazy-select-all-btn.v-btn {
  background: var(--v-blue500-base);
  span span {
    color: white!important;
  }
}
.ui-btn-width.lazy-clear-all-btn.v-btn {
  background: #EBF5FF;
  span span {
    color: var(--v-blue500-base)!important;
  }
}
</style>
